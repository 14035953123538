/* eslint-disable import/no-unresolved */
/* eslint-disable react/prop-types */
import React from 'react';
import { graphql } from 'gatsby';
import { HomePage } from '@page-templates';

export default ({ location, data }) => (
  <HomePage
    location={location}
    htmlTitle="Євген Кривонос"
    htmlDescription=""
    htmlKeywords={[]}
    title="Євген Кривонос"
  />
);

export const pageQuery = graphql`
  query HomePageQueryUk {
    site {
      siteMetadata {
        languages {
          defaultLangKey
          langs
        }
      }
    }
  }
`;
